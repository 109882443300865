import React, { useState } from "react"
import loadable from "@loadable/component"
import "../../css/typography-cerabielefeld.css"
const ChatComponent = loadable(() => import("../../components/Chat Iframe"))

function Chat() {
  const [hideSendBox, setHideSendBox] = useState(true)
  // doing this loadable import so createStore works
  // https://stackoverflow.com/questions/65398447/webpackerror-typeerror-object-is-not-a-function-gatsbyjs-build-error-bot
  return (
    <ChatComponent
      name="regioit"
      // token="AhaaisD-ce0.7csNC_FrUbij1tjiy_bqmh-hON-1H7nc0SA-L5RG4jE" // bot-process-dev-01
      token="-BeR0fBw97I.tXA01fMOkW4qALJZ9KvPzRUM4Y7Smfi0xrYi2xus8-s" // bots-prod-01
      // token="WOlq3GY0dKg.zirmmlwAPEsbzIFbjRXNd8fyXGNT5000H03CWR_kG_Q" // direct line local development
      botId="ddxng5wgkx4esxti"
      domain="https://europe.directline.botframework.com/v3/directline"
      webSocket={true}
      hasGDPR={false}
      onHideSendBox={value => setHideSendBox(value)}
      avatar="https://static.convaise.com/avatars/bielefeld/BIE-aktiv.svg"
      avatarInitials="BI"
      autoFocusVersion="v1"
      datePickerVersion="v1"
      // gdprButtonText="Zustimmen"
      // gdprText={
      //   <>
      //     Die{" "}
      //     <a
      //       href="https://www.gegenbauer.de/datenschutzerklaerung/"
      //       target="_blank"
      //     >
      //       Datenschutzerklärung-
      //     </a>{" "}
      //     habe ich zur Kenntnis genommen und ich erkläre mich damit
      //     einverstanden, dass meine Daten zum Zwecke der Bearbeitung meiner
      //     Anfrage verarbeitet werden. Ihre Einwilligung können Sie jederzeit
      //     widerrufen. Durch den Widerruf der Einwilligung wird die
      //     Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf
      //     erfolgten Verarbeitung nicht berührt.
      //   </>
      // }
      // adaptiveCardsHostConfig={{
      //   containerStyles: {
      //     emphasis: {
      //       backgroundColor: "rgb(227,0,20)",
      //       foregroundColors: {
      //         default: {
      //           default: "#fff",
      //           subtle: "#484644",
      //         },
      //       },
      //     },
      //     accent: {
      //       backgroundColor: "rgb(227,0,20)",
      //       foregroundColors: {
      //         default: {
      //           default: "#fff",
      //           subtle: "#484644",
      //         },
      //       },
      //     },
      //   },
      //   actions: {
      //     actionAlignment: "stretch",
      //     actionsOrientation: "vertical",
      //     buttonSpacing: 8,
      //     maxActions: 100,
      //     showCard: {
      //       actionMode: "inline",
      //       inlineTopMargin: 8,
      //     },
      //     spacing: "default",
      //   },
      // }}
      styleOptions={{
        primaryFont: '"CeraBielefeld",Arial,sans-serif',
        bubbleFromUserTextColor: "white",
        bubbleTextColor: "#293754",
        bubbleFromUserBackground: "rgb(227,0,20)",
        botAvatarBackgroundColor: "#fff",
        hideSendBox,
        avatarBorderRadius: "0%",
        avatarSize: "40px",
        // bubbleFromUserNubSize: "0",
        // bubbleNubSize: "20px",
        // bubbleNubOffset: 0,
        transcriptVisualKeyboardIndicatorWidth: 1,
        transcriptActivityVisualKeyboardIndicatorWidth: 1,
        autoScrollSnapOnActivity: true,
        autoScrollSnapOnActivityOffset: 100,
        // autoScrollSnapOnPage: true,
        // autoScrollSnapOnPageoffset: 400,
        // hideSendBox: true,
        // primaryFont:
        //   'muli,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
      }}
      overrideLocalizedStrings={{
        TEXT_INPUT_PLACEHOLDER: "Gesuchte Dienstleistung...",
      }}
      styleCustomizations={{
        "--button-blue": "rgb(227,0,20)",
        "--button-blue-light": "rgb(196,13,14)",
        "--button-orange": "rgb(96,129,146)",
        "--button-orange-light": "rgb(77,112,129)",
        "--button-orange-text": "#fff",
        "--button-red": "rgb(227,0,20)",
        "--button-red-light": "rgb(196,13,14)",
        "--button-green": "rgba(0, 131, 50)",
        "--button-green-light": "rgba(0,124,47)",
        "--font": '"CeraBielefeld",Arial,sans-serif',
        "--button-font-size": "16px",
        "--button-font-weight": "400",
        "--button-border-radius": "0",
        "--button-box-shadow": "rgb(0 0 0 / 25%) 0px 0px 20px 0px",
        "--button-padding": "8px 24px !important",
        "--button-height": "42px",
        "--card-border-radius": "20px",
        "--font-size-normal": "16px",
        "--line-height-normal": "1.5em",
        "--font-size-large": "36px",
        "--avatar-size-small:": "40px",
      }}
      // css="/convaise-assistant.css"
    />
  )
}

export default Chat
